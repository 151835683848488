import './text-image.scss';

class TextImage {
    constructor(element) {
        this.$root = element;

        this.initialize();
    }

    initialize() {
        // Usercentrics Smart Data Protector is not loaded instantly
        // Wait for it to be loaded, modify html elements and stop the interval

        const baseSelector =
            'div.uc-embedding-wrapper:not(.hero-slider__slides div.uc-embedding-wrapper)';

        const intUCimage = setInterval(() => {
            [
                `${baseSelector} h3`,
                `${baseSelector} p`,
                `${baseSelector} button`,
                `${baseSelector} a`
            ].forEach((selector) => {
                const elements = this.$root?.querySelectorAll(selector);
                [...elements].forEach((element) =>
                    element.setAttribute('tabindex', '0')
                );
            });

            if (
                typeof window.uc === 'object' &&
                typeof window.uc.blockOnly === 'function'
            ) {
                clearInterval(intUCimage);
            }
        }, 1000);
    }
}

export { TextImage };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        [...$context.querySelectorAll('[data-text-image="root"]')].forEach(
            (t) => {
                new TextImage(t);
            }
        );
    }
});
